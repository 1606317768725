import React from 'react'
import { useSelector } from 'react-redux'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import IntlMessages from '../../util/IntlMessages'
import {
	NAV_STYLE_ABOVE_HEADER,
	NAV_STYLE_BELOW_HEADER,
	NAV_STYLE_DEFAULT_HORIZONTAL,
	NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from '../../constants/ThemeSetting'

const SubMenu = Menu.SubMenu
// const MenuItemGroup = Menu.ItemGroup;

const HorizontalNav = () => {
	const navStyle = useSelector(({ settings }) => settings.navStyle)
	const pathname = useSelector(({ common }) => common.pathname)

	const getNavStyleSubMenuClass = navStyle => {
		switch (navStyle) {
			case NAV_STYLE_DEFAULT_HORIZONTAL:
				return 'gx-menu-horizontal gx-submenu-popup-curve'
			case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
				return 'gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve'
			case NAV_STYLE_BELOW_HEADER:
				return 'gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve'
			case NAV_STYLE_ABOVE_HEADER:
				return 'gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve'
			default:
				return 'gx-menu-horizontal'
		}
	}

	const selectedKeys = pathname.substr(1)
	const defaultOpenKeys = selectedKeys.split('/')[1]
	return (
		<Menu
			defaultOpenKeys={[defaultOpenKeys]}
			selectedKeys={[selectedKeys]}
			mode='horizontal'
		>
			{/* <SubMenu
				popupClassName={getNavStyleSubMenuClass(navStyle)}
				key='main'
				title={<IntlMessages id='sidebar.main' />}
			>
				<Menu.Item key='dashboard'>
					<Link to='/dashboard'>
						<i className='icon icon-dasbhoard' />
						<IntlMessages id='sidebar.dashboard' />
					</Link>
				</Menu.Item>
			</SubMenu> */}
{/* 
			<SubMenu
				popupClassName={getNavStyleSubMenuClass(navStyle)}
				key='donors-main'
				title={<IntlMessages id='sidebar.donors' />}
			>
				<Menu.Item key='donors'>
					<Link to='/donors'>
						<i className='icon icon-profile' />
						<IntlMessages id='sidebar.donors' />
					</Link>
				</Menu.Item>
			</SubMenu> */}

			<SubMenu
				popupClassName={getNavStyleSubMenuClass(navStyle)}
				key='beneficiary-main'
				title={<IntlMessages id='sidebar.beneficiary' />}
			>
				<Menu.Item key='beneficiary'>
					<Link to='/beneficiary'>
						<i className='icon icon-auth-screen' />
						<IntlMessages id='sidebar.beneficiary' />
					</Link>
				</Menu.Item>
			</SubMenu>

			<SubMenu
				popupClassName={getNavStyleSubMenuClass(navStyle)}
				key='admin-main'
				title={<IntlMessages id='sidebar.admin' />}
			>
				<Menu.Item key='admin'>
					<Link to='/admin'>
						<i className='icon icon-signup' />
						<IntlMessages id='sidebar.admin' />
					</Link>
				</Menu.Item>
			</SubMenu>

			<SubMenu
				popupClassName={getNavStyleSubMenuClass(navStyle)}
				key='admin-language'
				title={<IntlMessages id='sidebar.admin.language' />}
			>
				<Menu.Item key='language'>
					<Link to='/language'>
						<i className='icon icon-translation' />
						<IntlMessages id='sidebar.admin.language' />
					</Link>
				</Menu.Item>
			</SubMenu>

			<SubMenu
				popupClassName={getNavStyleSubMenuClass(navStyle)}
				key='donationProduct-main'
				title={<IntlMessages id='sidebar.donationProduct' />}
			>
				<Menu.Item key='donationProduct'>
					<Link to='/donation-product'>
						<i className='icon icon-product-list' />
						<IntlMessages id='sidebar.donationProduct' />
					</Link>
				</Menu.Item>
			</SubMenu>

			<SubMenu
				popupClassName={getNavStyleSubMenuClass(navStyle)}
				key='donationItem-main'
				title={<IntlMessages id='sidebar.donationItem' />}
			>
				<Menu.Item key='donationItem'>
					<Link to='/product'>
						<i className='icon icon-product-grid' />
						<IntlMessages id='sidebar.donationItem' />
					</Link>
				</Menu.Item>
			</SubMenu>

			<SubMenu
				popupClassName={getNavStyleSubMenuClass(navStyle)}
				key='donationOffline-main'
				title={<IntlMessages id='sidebar.payments' />}
			>
				<Menu.Item key='donationOffline'>
					<Link to='/donation-offline'>
						<i className='icon icon-editor' />
						<IntlMessages id='sidebar.payments' />
					</Link>
				</Menu.Item>
			</SubMenu>

			<SubMenu
				popupClassName={getNavStyleSubMenuClass(navStyle)}
				key='transaction-main'
				title={<IntlMessages id='sidebar.transaction' />}
			>
				<Menu.Item key='transaction'>
					<Link to='/transaction'>
						<i className='icon icon-copy' />
						<IntlMessages id='sidebar.transaction' />
					</Link>
				</Menu.Item>
			</SubMenu>

		</Menu>
	)
}

HorizontalNav.propTypes = {}

export default HorizontalNav
