import RequestService from './Request';

var create = (name, code, mandatory) => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/language',
			method: 'POST',
			body: {
				name: name,
				code: code,
				mandatory: mandatory
			},
			addToken: true
		};
		RequestService.send(req).then(
			res => {
				return resolve(res);
			},
			err => {
				return reject(err);
			}
		);
	});
};

var activate = id => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/language/' + id + '/activate',
			method: 'PUT',
			addToken: true
		};
		RequestService.send(req).then(
			res => {
				return resolve(res);
			},
			err => {
				return reject(err);
			}
		);
	});
};

var deactivate = id => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/language/' + id + '/deactivate',
			method: 'PUT',
			addToken: true
		};
		RequestService.send(req).then(
			res => {
				return resolve(res);
			},
			err => {
				return reject(err);
			}
		);
	});
};

var getAll = () => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/language',
			method: 'GET',
			addToken: true
		};
		RequestService.send(req).then(
			res => {
				return resolve(res);
			},
			err => {
				return reject(err);
			}
		);
	});
};

var get = id => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/language/' + id,
			method: 'GET',
			addToken: true
		};
		RequestService.send(req).then(
			res => {
				return resolve(res);
			},
			err => {
				return reject(err);
			}
		);
	});
};

var search = (
	query = undefined,
	oldestFirst = undefined,
	itemsPerPage = undefined,
	pageIndex = undefined
) => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/admin/language/',
			method: 'PUT',
			body: {
				query: query,
				oldestFirst: oldestFirst,
				pagination:
					itemsPerPage || pageIndex
						? {
								itemsPerPage: itemsPerPage,
								pageIndex: pageIndex
						  }
						: undefined
			},
			addToken: true
		};
		RequestService.send(req).then(
			res => {
				return resolve(res);
			},
			err => {
				return reject(err);
			}
		);
	});
};

const adminLanguage = {
	activate,
	deactivate,
	getAll,
	get,
	search,
	create
};

export default adminLanguage;
