import React from 'react'
import { Avatar, Popover } from 'antd'
import { useHistory } from 'react-router-dom'
import AuthService from './../../services/Auth'
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";

const UserProfile = () => {
	const history = useHistory()

	const userMenuOptions = (
		<ul className='gx-user-popover'>
			{/* <li>My Account</li> */}
			<li
				onClick={() => {
					AuthService.logout()
					history.push('/signin')
				}}
			>
				Logout
			</li>
		</ul>
	)

	return (
		<div className='gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row'>
			<Popover
				placement='bottomRight'
				content={userMenuOptions}
				trigger='click'
			>
				<Avatar className="gx-mr-2" size="large" icon={<UserOutlined />}
				// 	src={'https://via.placeholder.com/150'}
				// 	className='gx-size-40 gx-pointer gx-mr-3'
				// 	alt=''
				/>
				<span className='gx-avatar-name'>
					Administrator
					<i className='icon icon-chevron-down gx-fs-xxs gx-ml-2' />
				</span>
			</Popover>
		</div>
	)
}

export default UserProfile
