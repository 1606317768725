import React, { useState, useEffect, useRef } from 'react';
import { Button, message, Form, Input } from 'antd';
// import Icon from '@ant-design/icons'
import { Link, useHistory, useParams } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import AuthService from './../services/Auth';

// import {useDispatch, useSelector} from 'react-redux'
// import {
//   hideMessage,
//   showAuthLoader,
//   userFacebookSignIn,
//   userGithubSignIn,
//   userGoogleSignIn,
//   userSignUp,
//   userTwitterSignIn
// } from 'appRedux/actions/Auth'

import IntlMessages from 'util/IntlMessages';
// import {message} from 'antd/lib/index'
// import CircularProgress from 'components/CircularProgress/index'
// import GoogleOutlined from '@ant-design/icons/lib/icons/GoogleOutlined'
// import FacebookOutlined from '@ant-design/icons/lib/icons/FacebookOutlined'
// import GithubOutlined from '@ant-design/icons/lib/icons/GithubOutlined'
// import TwitterOutlined from '@ant-design/icons/lib/icons/TwitterOutlined'

const FormItem = Form.Item;

const ResetPassword = () => {
	const [loading, setLoading] = useState(false);
	const [verifyStatus, setVerifyStatus] = useState('loading');
	const { token } = useParams();
	const ref = useRef(null);

	const history = useHistory();
	// const dispatch = useDispatch()
	// const {loader, alertMessage, showMessage, authUser} = useSelector(({auth}) => auth)

	useEffect(() => {
		if (AuthService.status()) {
			AuthService.reset();
		}
		ref.current?.continuousStart();
		AuthService.verifyEmailLinkData(token.toString()).then(
			res => {
				ref.current?.complete();
				setVerifyStatus(res.state);
			},
			err => {
				ref.current?.complete();
				console.error(err);
			}
		);
	}, [token]);

	// const onFinishFailed = errorInfo => {
	//   console.log('Failed:', errorInfo)
	// }

	const onFinish = values => {
		if (!loading) {
			if (values.password === values.confirm) {
				setLoading(true);
				ref.current?.continuousStart();
				AuthService.resetPassword(token, values.password).then(
					res => {
						setLoading(false);
						ref.current?.complete();
						if (res.state === 'success') {
							history.push('/home');
							message.success(res.message);
						} else {
							message.warning(res.message);
						}
					},
					err => {
						setLoading(false);
						ref.current?.complete();
						console.error(err);
					}
				);
			} else {
				message.warning("Passwords don't match");
			}
		}
	};

	return verifyStatus === 'success' ? (
		<div className='gx-app-login-wrap'>
			<LoadingBar height={3} color='#0C8F4D' ref={ref} />
			<div className='gx-app-login-container'>
				<div className='gx-app-login-main-content'>
					<div className='gx-app-logo-content'>
						<div className='gx-app-logo-content-bg'>
							<img src='/assets/images/login-image.jpg' alt='Login' />
						</div>
						<div className='gx-app-logo-wid'>
							<h1>
								<IntlMessages id='app.userAuth.reset' />
							</h1>
							<p>
								<IntlMessages id='app.userAuth.confirmReset' />
							</p>
							{/* <p><IntlMessages id='app.userAuth.getAccount' /></p> */}
						</div>
						<div className='gx-app-logo'>
							<img
								alt='NoMorePoverty'
								src='/assets/images/logo.svg'
								style={{ height: '5vmin' }}
							/>
						</div>
					</div>

					<div className='gx-app-login-content'>
						<Form
							initialValues={{ remember: true }}
							name='basic'
							onFinish={onFinish}
							// onFinishFailed={onFinishFailed}
							className='gx-signin-form gx-form-row0'
						>
							<FormItem
								rules={[
									{ required: true, message: 'Please input your Password!' }
								]}
								name='password'
							>
								<Input type='password' placeholder='Password' />
							</FormItem>

							<FormItem
								rules={[
									{ required: true, message: 'Please confirm your password!' }
								]}
								name='confirm'
							>
								<Input placeholder='Retype New Password' type='password' />
							</FormItem>
							<FormItem>
								<Button type='primary' className='gx-mb-0' htmlType='submit'>
									<IntlMessages id='app.userAuth.confirmResetButton' />
								</Button>
								{/* <span><IntlMessages id='app.userAuth.or'/></span> <Link to='/signin'><IntlMessages
                id='app.userAuth.signIn'/></Link> */}
							</FormItem>
							<div className='gx-flex-row gx-justify-content-between'>
								{/* <span>or connect with</span> */}
								<ul className='gx-social-link'>
									{/* <li>
                    <GoogleOutlined onClick={() => {
                      dispatch(showAuthLoader())
                      dispatch(userGoogleSignIn())
                    }}/>
                  </li>
                  <li>
                    <FacebookOutlined onClick={() => {
                      dispatch(showAuthLoader())
                      dispatch(userFacebookSignIn())
                    }}/>
                  </li>
                  <li>
                    <GithubOutlined  onClick={() => {
                      dispatch(showAuthLoader())
                      dispatch(userGithubSignIn())
                    }}/>
                  </li>
                  <li>
                    <TwitterOutlined onClick={() => {
                      dispatch(showAuthLoader())
                      dispatch(userTwitterSignIn())
                    }}/>
                  </li> */}
								</ul>
							</div>
						</Form>
					</div>

					{/* {loader &&
          <div className='gx-loader-view'>
            <CircularProgress/>
          </div>
          }
          {showMessage &&
          message.error(alertMessage)} */}
				</div>
			</div>
		</div>
	) : (
		<div className='gx-login-container'>
			<LoadingBar height={3} color='#0C8F4D' ref={ref} />
			<div className='gx-login-content'>
				<div className='gx-login-header'>
					<img src='/assets/images/logo.svg' alt='NMP' title='NMP' />
				</div>
				{verifyStatus === 'loading' ? (
					<div className='gx-mb-4'>
						<h2>
							<IntlMessages id='app.userAuth.verifyUserEmail.Verifying' />
						</h2>
					</div>
				) : null}
				{verifyStatus === 'failure' ? (
					<div className='gx-mb-4'>
						<h2>
							<IntlMessages id='app.userAuth.verifyUserEmail.Failure' />
						</h2>
						<p>
							<IntlMessages id='app.userAuth.verifyUserEmail.Failure.message' />
						</p>
						<Button type='primary'>
							<Link to='/resetpassword'>Try Again</Link>
						</Button>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default ResetPassword;
