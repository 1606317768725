import React, { useState, useEffect, useRef } from 'react';
import {
	Button,
	Card,
	Table,
	Checkbox,
	Input,
	Modal,
	Form,
	Cascader,
	message,
	Row,
	Col
} from 'antd';
import IntlMessages from 'util/IntlMessages';
import ProductManagement from './../../services/Admin/AdminManagement';
import Languages from './../../services/Admin/AdminLanguage';
import RoleService from './../../services/Role';
import LoadingBar from 'react-top-loading-bar';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 8 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 16 }
	}
};

const Search = Input.Search;

const AdminLanguage = () => {
	const [form] = Form.useForm();

	const ref = useRef(null);
	const [loading, setLoading] = useState(false);
	const [languageList, setLanguageList] = useState([]);
	const [pagination, setPagination] = useState({
		totalItems: 0,
		itemsPerPage: 10,
		totalPages: 0,
		pageIndex: 1
	});
	const [oldestFirst, setOldestFirst] = useState(false);
	const [query, setQuery] = useState('');

	const [visible, setVisible] = useState(false);

	const showModal = () => {
		setVisible(true);
	};

	const hideModal = () => {
		setVisible(false);
	};

	const handleCancel = () => {
		setVisible(false);
		form.resetFields();
	};

	const languageMandatoryList = ['True', 'False'];

	const search = (query, oldestFirst, pagination) => {
		if (!loading) {
			setLoading(true);
			ref.current?.continuousStart();
			Languages.search(
				query,
				oldestFirst,
				pagination.itemsPerPage,
				pagination.pageIndex
			).then(
				res => {
					if (res.state === 'success') {
						console.log(res);
						setPagination(res.pagination);
						setLanguageList(res.languages);
					}
					setLoading(false);
					ref.current?.complete();
				},
				err => {
					console.error(err);
					setLoading(false);
					ref.current?.complete();
				}
			);
		}
	};

	const activate = id => {
		if (!loading) {
			setLoading(true);
			ref.current?.continuousStart();
			Languages.activate(id).then(
				res => {
					setLoading(false);
					ref.current?.complete();
					if (res.state === 'success') {
						message.success(res.message);
						search(query, oldestFirst, pagination);
					} else {
						message.warning(res.message);
					}
				},
				err => {
					console.error(err);
					setLoading(false);
					ref.current?.complete();
				}
			);
		}
	};

	const deactivate = id => {
		if (!loading) {
			setLoading(true);
			ref.current?.continuousStart();
			Languages.deactivate(id).then(
				res => {
					setLoading(false);
					ref.current?.complete();
					if (res.state === 'success') {
						message.success(res.message);
						search(query, oldestFirst, pagination);
					} else {
						message.warning(res.message);
					}
				},
				err => {
					console.error(err);
					setLoading(false);
					ref.current?.complete();
				}
			);
		}
	};

	var columns = [
		{
			title: 'Name',
			width: 50,
			dataIndex: 'name',
			key: 'names',
			fixed: 'left'
		},
		{ title: 'Code', dataIndex: 'code', width: 50, key: 'code' }
	];
	if (RoleService.hasAccess('Admin', 'rw')) {
		const actions = [
			{
				title: 'Action',
				fixed: 'right',
				width: 18,
				dataIndex: 'active',
				render: (text, record, index) => {
					return (
						<span
							className='gx-link'
							onClick={() => {
								if (text) deactivate(record._id);
								else activate(record._id);
							}}
						>
							{text ? 'Deactivate' : 'Activate'}
						</span>
					);
				}
			}
		];
		columns.push(...actions);
	}

	const processAdmin = values => {
		console.log(values);
		if (!loading) {
			setLoading(true);
			Languages.create(values.name, values.code, values.mandatory[0]).then(
				res => {
					setLoading(false);
					if (res.state === 'success') {
						message.success(res.message);
						setVisible(false);
						form.resetFields();
						search(query, oldestFirst, pagination);
					} else {
						message.warning(res.message);
					}
				},
				err => {
					setLoading(false);
					console.error(err);
				}
			);
		}
	};

	useEffect(() => {
		search(query, oldestFirst, pagination);
		if (RoleService.hasAccess('Admin', 'rw')) {
			ProductManagement.getAllRoles().then(
				res => {
					if (res.state === 'success') {
						var parsedRoles = [];
						var key = 0;
						res.roles.forEach(role => {
							role.accesses.forEach(access => {
								const parsedRole = {
									title: role.name,
									access: access,
									key: key.toString()
								};
								key++;
								parsedRoles.push(parsedRole);
							});
						});
					}
				},
				err => {
					console.error(err);
				}
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{RoleService.hasAccess('Admin', 'rw') ? (
				<Card title='Create a New Language'>
					<Button type='primary' onClick={showModal}>
						Create
					</Button>
					<Modal
						title={'Create a New Language'}
						open={visible}
						onOk={hideModal}
						onCancel={handleCancel}
						width={800}
						centered={true}
						footer={null}
					>
						<Row>
							<Col xl={24} lg={24} md={24} sm={24} xs={24}>
								<h1
									style={{
										margin: 0,
										// color: '#535353',
										fontWeight: 500,
										fontSize: '16px',
										lineHeight: '22px',
										wordWrap: 'breakWord'
									}}
								>
									<IntlMessages id='nav.language.information.formTitle' />
								</h1>
							</Col>
							<Col xl={24} lg={24} md={24} sm={24} xs={24}>
								<Form
									{...formItemLayout}
									form={form}
									name='register'
									onFinish={processAdmin}
									scrollToFirstError
								>
									<Form.Item
										name='name'
										label='Name'
										rules={[
											{
												required: true,
												message: 'Please input the name of the Language',
												whitespace: true
											}
										]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										name='code'
										label='Code'
										rules={[
											{
												required: true,
												message: 'Please input the code of the Language',
												whitespace: true
											}
										]}
									>
										<Input />
									</Form.Item>
									<Form.Item
										name='mandatory'
										label='Mandatory'
										rules={[
											{
												required: true,
												message: 'Please input the description of the Product'
											}
										]}
									>
										<Cascader
											options={languageMandatoryList.map(item => {
												return {
													value: item === 'True' ? true : false,
													label: item
												};
											})}
										/>
									</Form.Item>
									<Button
										type='primary'
										htmlType='submit'
										style={{ float: 'left' }}
									>
										Create
									</Button>
								</Form>
							</Col>
						</Row>
					</Modal>
				</Card>
			) : null}
			<LoadingBar height={3} color='#7dd078' ref={ref} />
			<Card title='Language Search'>
				<Search
					placeholder='Find Language by Name or Code'
					onSearch={value => {
						setQuery(value);
						search(value, oldestFirst, pagination);
					}}
					enterButton
					size='large'
				/>
				<Checkbox
					style={{ marginTop: '15px' }}
					onChange={checkedValue => {
						setOldestFirst(checkedValue.target.checked);
						search(query, checkedValue.target.checked, pagination);
					}}
				>
					Oldest First
				</Checkbox>
				<Table
					columns={columns}
					dataSource={languageList}
					scroll={{ x: 1300 }}
					pagination={{
						position: ['topRight', 'bottomRight'],
						total: pagination.totalItems,
						showTotal: (total, range) =>
							`${range[0]}-${range[1]} of ${total} items`,
						defaultCurrent: 1,
						defaultPageSize: 5,
						showSizeChanger: true,
						pageSizeOptions: [5, 10, 20, 50, 100],
						onChange: (page, pageSize) => {
							var tempPagination = Object.assign({}, pagination);
							tempPagination.itemsPerPage = pageSize;
							tempPagination.pageIndex = page;
							setPagination(tempPagination);
							search(query, oldestFirst, tempPagination);
						},
						onShowSizeChange: (current, size) => {
							var tempPagination = Object.assign({}, pagination);
							tempPagination.pageIndex = 1;
							tempPagination.itemsPerPage = size;
							setPagination(tempPagination);
							search(query, oldestFirst, tempPagination);
						}
					}}
				/>
			</Card>
		</>
	);
};

export default AdminLanguage;
