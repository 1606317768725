import React from 'react';
import { Route, Switch } from 'react-router-dom';

import asyncComponent from 'util/asyncComponent';
import Admin from './Admin/index';
import Product from './Product/index';
import DonationProduct from './DonationProduct/index';
import DonationOffline from './DonationOffline/index';
import Transaction from './Transaction/index';
import AdminLanguage from './AdminLanguage/index';
import Donors from './Donors';

const App = ({ match }) => (
	<div className='gx-main-content-wrapper'>
		<Switch>
			{/* <Route path={`${match.url}beneficiary-form`} component={asyncComponent(() => import('./SamplePage'))}/> */}
			<Route
				path={`${match.url}beneficiary`}
				component={asyncComponent(() => import('./Beneficiary'))}
			/>
			<Route path={`${match.url}admin`} component={Admin} />
			<Route path={`${match.url}product`} component={Product} />
			<Route
				path={`${match.url}donation-product`}
				component={DonationProduct}
			/>
			<Route
				path={`${match.url}donation-offline`}
				component={DonationOffline}
			/>
			<Route path={`${match.url}transaction`} component={Transaction} />
			<Route path={`${match.url}language`} component={AdminLanguage} />
			<Route path={`${match.url}donors`} component={Donors} />
		</Switch>
	</div>
);

export default App;
