import React, { useState, useEffect, useRef } from 'react'
import { Button, message, Form, Input } from 'antd'
// import Icon from '@ant-design/icons'
import LoadingBar from 'react-top-loading-bar'
import { Link, useHistory } from 'react-router-dom'

// import { useDispatch, useSelector } from 'react-redux'
// import {
// 	hideMessage,
// 	// showAuthLoader,
// 	// userFacebookSignIn,
// 	// userGithubSignIn,
// 	// userGoogleSignIn,
// 	// userSignUp,
// 	// userTwitterSignIn
// } from 'appRedux/actions/Auth'

import IntlMessages from 'util/IntlMessages'
// import { message } from 'antd/lib/index'
// import CircularProgress from 'components/CircularProgress/index'
// import GoogleOutlined from '@ant-design/icons/lib/icons/GoogleOutlined'
// import FacebookOutlined from '@ant-design/icons/lib/icons/FacebookOutlined'
// import GithubOutlined from '@ant-design/icons/lib/icons/GithubOutlined'
// import TwitterOutlined from '@ant-design/icons/lib/icons/TwitterOutlined'
import AuthService from './../services/Auth'

const FormItem = Form.Item

const ResetPassword = () => {
	const [loading, setLoading] = useState(false)
	const history = useHistory()
	const ref = useRef(null)

	// const dispatch = useDispatch()
	// const { loader, alertMessage, showMessage, authUser } = useSelector(({ auth }) => auth)

	useEffect(() => {
		if (AuthService.status()) {
			history.push('/home')
		}
	}, [history])

	// const onFinishFailed = errorInfo => {
	// 	console.log('Failed:', errorInfo)
	// }

	const onFinish = values => {
		if (!loading) {
			setLoading(true)
			ref.current?.continuousStart()
			AuthService.sendEmailVerificationLink(values.email).then(
				res => {
					setLoading(false)
					ref.current?.complete()
					if (res.state === 'success') {
						history.push('/signin')
						message.success(res.message)
					} else {
						message.warning(res.message)
					}
				},
				err => {
					setLoading(false)
					ref.current?.complete()
					console.error(err)
				}
			)
		}
	}

	return (
		<div className='gx-app-login-wrap'>
			<LoadingBar height={3} color='#7dd078' ref={ref} />
			<div className='gx-app-login-container'>
				<div className='gx-app-login-main-content'>
					<div className='gx-app-logo-content'>
						<div className='gx-app-logo-content-bg'>
							<img src='/assets/images/login-image.jpg' alt='Login' />
						</div>
						<div className='gx-app-logo-wid'>
							<h1>
								<IntlMessages id='app.userAuth.resetPassword' />
							</h1>
							<p>
								<IntlMessages id='app.userAuth.byReset' />
							</p>
							{/* <p><IntlMessages id='app.userAuth.getAccount' /></p> */}
						</div>
						<div className='gx-app-logo'>
							<img
								alt='no-more-poverty'
								src='/assets/images/logo.svg'
								style={{ height: '5vmin' }}
							/>
						</div>
					</div>

					<div className='gx-app-login-content'>
						<Form
							// initialValues={{ remember: true }}
							name='basic'
							onFinish={onFinish}
							// onFinishFailed={onFinishFailed}
							className='gx-signin-form gx-form-row0'
						>
							<FormItem
								name='email'
								rules={[
									{
										required: true,
										type: 'email',
										message: 'The input is not valid E-mail!'
									}
								]}
							>
								<Input placeholder='Email' />
							</FormItem>
							<br></br>
							<br></br>
							<FormItem>
								<Button type='primary' className='gx-mb-0' htmlType='submit'>
									<IntlMessages id='app.userAuth.resetPassword' />
								</Button>
								<span>
									<IntlMessages id='app.userAuth.or' />
								</span>{' '}
								<Link to='/signin'>
									<IntlMessages id='app.userAuth.signIn' />
								</Link>
							</FormItem>
						</Form>
					</div>
					{/* {loader &&
						<div className='gx-loader-view'>
							<CircularProgress />
						</div>
					} */}
					{/* {showMessage &&
						message.error(alertMessage)} */}
				</div>
			</div>
		</div>
	)
}

export default ResetPassword
