import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';

import AppLocale from '../../lngProvider';
import MainApp from './MainApp';
import SignIn from './../SignIn';
import ResetPassword from './../ResetPassword';
import VerifyAdminEmail from '../VerifyAdminEmail';
// import VerifyUserEmail from '../VerifyUserEmail'

import {
	LAYOUT_TYPE_BOXED,
	LAYOUT_TYPE_FRAMED,
	LAYOUT_TYPE_FULL,
	NAV_STYLE_ABOVE_HEADER,
	NAV_STYLE_BELOW_HEADER,
	NAV_STYLE_DARK_HORIZONTAL,
	NAV_STYLE_DEFAULT_HORIZONTAL,
	NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
	THEME_TYPE_DARK
} from '../../constants/ThemeSetting';

import ClockService from './../../services/Clock';

const setLayoutType = layoutType => {
	if (layoutType === LAYOUT_TYPE_FULL) {
		document.body.classList.remove('boxed-layout');
		document.body.classList.remove('framed-layout');
		document.body.classList.add('full-layout');
	} else if (layoutType === LAYOUT_TYPE_BOXED) {
		document.body.classList.remove('full-layout');
		document.body.classList.remove('framed-layout');
		document.body.classList.add('boxed-layout');
	} else if (layoutType === LAYOUT_TYPE_FRAMED) {
		document.body.classList.remove('boxed-layout');
		document.body.classList.remove('full-layout');
		document.body.classList.add('framed-layout');
	}
};

const setNavStyle = navStyle => {
	if (
		navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
		navStyle === NAV_STYLE_DARK_HORIZONTAL ||
		navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
		navStyle === NAV_STYLE_ABOVE_HEADER ||
		navStyle === NAV_STYLE_BELOW_HEADER
	) {
		document.body.classList.add('full-scroll');
		document.body.classList.add('horizontal-layout');
	} else {
		document.body.classList.remove('full-scroll');
		document.body.classList.remove('horizontal-layout');
	}
};

const RestrictedRoute = ({
	component: Component,
	location,
	/*authUser,*/ ...rest
}) => (
	<Route
		{...rest}
		render={props => {
			const adminToken = localStorage.getItem('adminKey');
			const adminTokenExp = parseInt(localStorage.getItem('adminKeyExp'));
			const superAdminToken = localStorage.getItem('superAdminKey');
			const superAdminTokenExp = parseInt(
				localStorage.getItem('superAdminKeyExp')
			);
			var status = undefined;
			if (adminToken) {
				if (adminTokenExp > ClockService.now()) {
					// admin verified
					status = 'admin';
				}
			} else if (superAdminToken) {
				if (superAdminTokenExp > ClockService.now()) {
					// super admin verified
					status = 'superAdmin';
				}
			}

			return status === 'admin' || status === 'superAdmin' ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: '/signin',
						state: { from: location }
					}}
				/>
			);
		}}
	/>
);

let styleSheetLink = document.createElement('link');
styleSheetLink.type = 'text/css';
styleSheetLink.rel = 'stylesheet';
document.body.appendChild(styleSheetLink);

const App = props => {
	const { match, location } = props;
	const locale = useSelector(({ settings }) => settings.locale);
	const navStyle = useSelector(({ settings }) => settings.navStyle);
	const layoutType = useSelector(({ settings }) => settings.layoutType);
	const themeColor = useSelector(({ settings }) => settings.themeColor);
	const themeType = useSelector(({ settings }) => settings.themeType);
	const isDirectionRTL = useSelector(({ settings }) => settings.isDirectionRTL);
	//const history = useHistory();
	// const { /*authUser, */ initURL } = useSelector(({ auth }) => auth)

	// avoid Uncaught ReferenceError: process is not defined after react hot reload in the local development environment
	useEffect(() => {
		window.process = {
			...window.process
		};
	}, []);

	useEffect(() => {
		if (isDirectionRTL) {
			document.documentElement.classList.add('rtl');
			document.documentElement.setAttribute('data-direction', 'rtl');
		} else {
			document.documentElement.classList.remove('rtl');
			document.documentElement.setAttribute('data-direction', 'ltr');
		}

		if (themeColor) {
			styleSheetLink.href = `/css/${themeColor}.min.css`;
		}
	}, [themeColor, isDirectionRTL]);

	useEffect(() => {
		if (locale) document.documentElement.lang = locale.locale;
	}, [locale]);

	useEffect(() => {
		if (themeType === THEME_TYPE_DARK) {
			document.body.classList.add('dark-theme');
			styleSheetLink.href = '/css/dark_theme.min.css';
		} else if (document.body.classList.contains('dark-theme')) {
			document.body.classList.remove('dark-theme');
			styleSheetLink.href = '';
		}
	}, [themeType]);

	useEffect(() => {
		setLayoutType(layoutType);
		setNavStyle(navStyle);
	}, [layoutType, navStyle]);

	//???????????????????????? for what
	// useEffect(() => {
	// 	const adminToken = localStorage.getItem('adminKey');
	// 	const adminTokenExp = parseInt(localStorage.getItem('adminKeyExp'));
	// 	const superAdminToken = localStorage.getItem('superAdminKey');
	// 	const superAdminTokenExp = parseInt(
	// 		localStorage.getItem('superAdminKeyExp')
	// 	);

	// 	console.log(location.pathname);

	// 	var status = undefined;
	// 	if (location.pathname === '/') {
	// 		if (adminToken) {
	// 			if (adminTokenExp > ClockService.now()) {
	// 				// admin verified
	// 				status = 'admin';
	// 			}
	// 		} else if (superAdminToken) {
	// 			if (superAdminTokenExp > ClockService.now()) {
	// 				// super admin verified
	// 				status = 'superAdmin';
	// 			}
	// 		}

	// 		// if (status !== 'admin' && status !== 'superAdmin') {
	// 		// 	history.push('/signin')
	// 		// } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
	// 		// 	history.push('/home')
	// 		// } else {
	// 		// 	history.push(initURL)
	// 		// }
	// 	}
	// }, [/*authUser, */ location, history]);

	if (location.pathname === '/') {
		return <Redirect to={'/beneficiary/list'} />;
	}

	const currentAppLocale = AppLocale[locale.locale];

	return (
		<ConfigProvider
			locale={currentAppLocale.antd}
			direction={isDirectionRTL ? 'rtl' : 'ltr'}
		>
			<IntlProvider
				locale={currentAppLocale.locale}
				messages={currentAppLocale.messages}
			>
				<Switch>
					<Route exact path='/signin' component={SignIn} />
					<Route exact path='/resetpassword' component={ResetPassword} />
					<Route
						exact
						path='/admin/verify/:token'
						component={VerifyAdminEmail}
					/>
					{/* <Route
						exact
						path='/verify/user/email/:token'
						component={VerifyUserEmail}
					/> */}
					<RestrictedRoute
						path={`${match.url}`}
						// authUser={authUser}
						location={location}
						component={MainApp}
					/>
				</Switch>
			</IntlProvider>
		</ConfigProvider>
	);
};

export default App;
