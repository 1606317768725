import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

import CustomScrollbars from 'util/CustomScrollbars';
import SidebarLogo from './SidebarLogo';
import UserProfile from './UserProfile';
// import AppsNavigation from './AppsNavigation';
import {
	NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
	NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
	THEME_TYPE_LITE
} from '../../constants/ThemeSetting';
import IntlMessages from '../../util/IntlMessages';
import { useSelector } from 'react-redux';

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
	const { navStyle, themeType } = useSelector(({ settings }) => settings);
	const pathname = useSelector(({ common }) => common.pathname);

	const getNoHeaderClass = navStyle => {
		if (
			navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
			navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
		) {
			return 'gx-no-header-notifications';
		}
		return '';
	};

	// const getNavStyleSubMenuClass = navStyle => {
	// 	if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
	// 		return 'gx-no-header-submenu-popup'
	// 	}
	// 	return ''
	// }

	const selectedKeys = pathname.substr(1);
	const defaultOpenKeys = selectedKeys.split('/')[1];

	const MenuList = [
		// {
		// 	type: 'group',
		// 	key: 'main',
		// 	className: 'gx-menu-group',
		// 	label: <IntlMessages id='sidebar.dashboard' />,
		// 	children: [
		// 		{
		// 			key: 'dashboard',
		// 			label: (
		// 				<Link to='/dashboard'>
		// 					<i className='icon icon-dasbhoard' />
		// 					<span>
		// 						<IntlMessages id='sidebar.dashboard' />
		// 					</span>
		// 				</Link>
		// 			)
		// 		}
		// 	]
		// },
		// {
		// 	type: 'group',
		// 	key: 'donors-main',
		// 	className: 'gx-menu-group',
		// 	label: <IntlMessages id='sidebar.donors' />,
		// 	children: [
		// 		{
		// 			key: 'donors',
		// 			label: (
		// 				<Link to='/donors'>
		// 					<i className='icon icon-email' />
		// 					<span>
		// 						<IntlMessages id='sidebar.donors' />
		// 					</span>
		// 				</Link>
		// 			)
		// 		}
		// 	]
		// },
		{
			type: 'group',
			key: 'beneficiary-main',
			className: 'gx-menu-group',
			label: <IntlMessages id='sidebar.beneficiary' />,
			children: [
				{
					key: 'beneficiary',
					label: (
						<Link to='/beneficiary'>
							<i className='icon icon-auth-screen' />
							<span>
								<IntlMessages id='sidebar.beneficiary' />
							</span>
						</Link>
					)
				}
			]
		},
		{
			type: 'group',
			key: 'admin-main',
			className: 'gx-menu-group',
			label: <IntlMessages id='sidebar.admin' />,
			children: [
				{
					key: 'admin',
					label: (
						<Link to='/admin'>
							<i className='icon icon-signup' />
							<span>
								<IntlMessages id='sidebar.admin' />
							</span>
						</Link>
					)
				}
			]
		},
		{
			type: 'group',
			key: 'admin-language',
			className: 'gx-menu-group',
			label: <IntlMessages id='sidebar.admin.language' />,
			children: [
				{
					key: 'language',
					label: (
						<Link to='/language'>
							<i className='icon icon-translation' />
							<span>
								<IntlMessages id='sidebar.admin.language' />
							</span>
						</Link>
					)
				}
			]
		},
		{
			type: 'group',
			key: 'donationProduct-main',
			className: 'gx-menu-group',
			label: <IntlMessages id='sidebar.donationProduct' />,
			children: [
				{
					key: 'donationProduct',
					label: (
						<Link to='/donation-product'>
							<i className='icon icon-product-list' />
							<span>
								<IntlMessages id='sidebar.donationProduct' />
							</span>
						</Link>
					)
				}
			]
		},
		{
			type: 'group',
			key: 'donationItem-main',
			className: 'gx-menu-group',
			label: <IntlMessages id='sidebar.donationItem' />,
			children: [
				{
					key: 'donationItem',
					label: (
						<Link to='/product'>
							<i className='icon icon-product-grid' />
							<span>
								<IntlMessages id='sidebar.donationItem' />
							</span>
						</Link>
					)
				}
			]
		},
		// {
		// 	type: 'group',
		// 	key: 'donors-main',
		// 	className: 'gx-menu-group',
		// 	label: <IntlMessages id='sidebar.donors' />,
		// 	children: [
		// 		{
		// 			key: 'donors',
		// 			label: (
		// 				<Link to='/donors'>
		// 					<i className='icon icon-profile' />
		// 					<span>
		// 						<IntlMessages id='sidebar.donors' />
		// 					</span>
		// 				</Link>
		// 			)
		// 		}
		// 	]
		// },
		{
			type: 'group',
			key: 'donationOffline-main',
			className: 'gx-menu-group',
			label: <IntlMessages id='sidebar.payments' />,
			children: [
				{
					key: 'donationOffline',
					label: (
						<Link to='/donation-offline'>
							<i className='icon icon-editor' />
							<span>
								<IntlMessages id='sidebar.payments' />
							</span>
						</Link>
					)
				}
			]
		},
		{
			type: 'group',
			key: 'transaction-main',
			className: 'gx-menu-group',
			label: <IntlMessages id='sidebar.transaction' />,
			children: [
				{
					key: 'transaction',
					label: (
						<Link to='/transaction'>
							<i className='icon icon-copy' />
							<span>
								<IntlMessages id='sidebar.transaction' />
							</span>
						</Link>
					)
				}
			]
		}
	];

	return (
		<>
			<SidebarLogo
				sidebarCollapsed={sidebarCollapsed}
				setSidebarCollapsed={setSidebarCollapsed}
			/>
			<div className='gx-sidebar-content'>
				<div
					className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
				>
					<UserProfile />
					{/* <AppsNavigation /> */}
				</div>
				<CustomScrollbars className='gx-layout-sider-scrollbar'>
					<Menu
						defaultOpenKeys={[defaultOpenKeys]}
						selectedKeys={[selectedKeys]}
						theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
						mode='inline'
						items={MenuList}
					></Menu>
				</CustomScrollbars>
			</div>
		</>
	);
};

export default React.memo(SidebarContent);
