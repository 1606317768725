/* eslint-disable import/no-anonymous-default-export */
/**
 * @param {
 *   url: '/user/profile',
 *   method: 'POST',
 *   body: {
 *     name: 'John Doe'
 *   },
 *   addToken: true
 * } data
 */
var send = async data => {
	var request = {
		method: data.method.toUpperCase(),
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'app-key': process.env.REACT_APP_API_KEY
		}
	};
	if (data.method.toUpperCase() !== 'GET' && data.body) {
		request['body'] = JSON.stringify(data.body);
	}
	//console.log('data.addToken', data.addToken);
	if (data.addToken) {
		var adminToken = localStorage.getItem('adminKey');
		var superAdminToken = localStorage.getItem('superAdminKey');
		if (adminToken && adminToken.length > 10) {
			request.headers['admin-key'] = adminToken;
		} else if (superAdminToken && superAdminToken.length > 10) {
			request.headers['super-admin-key'] = superAdminToken;
		}
	}
	return fetch(process.env.REACT_APP_API_URL + data.url, request)
		.then(response => {
			return response.json();
		})
		.then(json => {
			return json;
		})
		.catch(err => {
			console.error(err);
			return Promise.reject(err);
		});
};

var sendMultipart = async data => {
	var request = {
		method: data.method.toUpperCase(),
		headers: {
			Accept: 'application/json',
			// 'Content-Type': 'multipart/form-data',
			'app-key': process.env.REACT_APP_API_KEY
		}
	};
	if (data.method.toUpperCase() !== 'GET' && data.body) {
		request['body'] = data.body;
	}
	if (data.addToken) {
		var adminToken = localStorage.getItem('adminKey');
		var superAdminToken = localStorage.getItem('superAdminKey');
		if (adminToken && adminToken.length > 10) {
			request.headers['admin-key'] = adminToken;
		} else if (superAdminToken && superAdminToken.length > 10) {
			request.headers['super-admin-key'] = superAdminToken;
		}
	}
	return fetch(process.env.REACT_APP_API_URL + data.url, request)
		.then(response => {
			return response.json();
		})
		.then(json => {
			return json;
		})
		.catch(err => {
			console.error(err);
			return Promise.reject(err);
		});
};

export default {
	send,
	sendMultipart
};
