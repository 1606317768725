import React, { useState, useEffect, useRef } from 'react';
import { Button, message, Input, Form } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import IntlMessages from 'util/IntlMessages';
// import TwitterOutlined from '@ant-design/icons/lib/icons/TwitterOutlined'
// import GithubOutlined from '@ant-design/icons/lib/icons/GithubOutlined'
// import FacebookOutlined from '@ant-design/icons/lib/icons/FacebookOutlined'
// import GoogleOutlined from '@ant-design/icons/lib/icons/GoogleOutlined'
import AuthService from './../services/Auth';

const SignIn = () => {
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const ref = useRef(null);

	useEffect(() => {
		if (AuthService.status()) {
			history.push('/home');
		}
	}, [history]);

	// const onFinishFailed = errorInfo => {
	// 	console.log('Failed:', errorInfo)
	// }

	const onFinish = values => {
		if (!loading) {
			setLoading(true);
			ref.current?.continuousStart();
			AuthService.login(values.username, values.password).then(
				res => {
					setLoading(false);
					ref.current?.complete();
					if (res.state === 'success') {
						history.push('/beneficiary');
						message.success(res.message);
					} else {
						message.warning(res.message);
					}
				},
				err => {
					setLoading(false);
					ref.current?.complete();
					console.error(err);
				}
			);
		}
	};

	return (
		<div className='gx-app-login-wrap'>
			<LoadingBar height={3} color='#7dd078' ref={ref} />
			<div className='gx-app-login-container'>
				<div className='gx-app-login-main-content'>
					<div className='gx-app-logo-content'>
						<div className='gx-app-logo-content-bg'>
							<img src='/assets/images/login-image.jpg' alt='Login' />
						</div>
						<div className='gx-app-logo-wid'>
							<h1>
								<IntlMessages id='app.userAuth.signIn' />
							</h1>
							<p>
								<IntlMessages id='app.userAuth.bySigning' />
							</p>
							<p>
								<IntlMessages id='app.userAuth.getAccount' />
							</p>
						</div>
						<div className='gx-app-logo'>
							<img
								alt='no-more-poverty'
								src='/assets/images/logo.svg'
								style={{ height: '5vmin' }}
							/>
						</div>
					</div>
					<div className='gx-app-login-content'>
						<Form
							initialValues={{ remember: true }}
							name='basic'
							onFinish={onFinish}
							// onFinishFailed={onFinishFailed}
							className='gx-signin-form gx-form-row0'
						>
							<Form.Item
								rules={[
									{ required: true, message: 'The input is not valid E-mail!' }
								]}
								name='username'
							>
								<Input placeholder='Username' />
							</Form.Item>
							<Form.Item
								rules={[
									{ required: true, message: 'Please input your Password!' }
								]}
								name='password'
							>
								<Input type='password' placeholder='Password' />
							</Form.Item>
							{/* <Form.Item>
								<Checkbox><IntlMessages id='appModule.iAccept' /></Checkbox>
								<span className='gx-signup-form-forgot gx-link'><IntlMessages
									id='appModule.termAndCondition' /></span>
							</Form.Item> */}
							<br></br>
							<br></br>
							<Form.Item>
								<Button className='gx-mb-0' type='primary' htmlType='submit'>
									<IntlMessages id='app.userAuth.signIn' />
								</Button>
								<span>
									<IntlMessages id='app.userAuth.or' />
								</span>{' '}
								<Link to='/resetpassword'>
									<IntlMessages id='app.userAuth.resetPassword' />
								</Link>
							</Form.Item>
						</Form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignIn;
