import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import asyncComponent from 'util/asyncComponent'

const Donors = ({ match }) => (
	<Switch>
		<Redirect exact from={`${match.url}/`} to={`${match.url}/list`} />
		<Route
			path={`${match.url}/list`}
			component={asyncComponent(() => import('./List'))}
		/>
	</Switch>
)

export default Donors
