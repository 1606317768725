import React from 'react'
import { Popover } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import AuthService from './../../services/Auth'

const UserInfo = () => {
	const history = useHistory()

	const userMenuOptions = (
		<ul className='gx-user-popover'>
			{/* <li>My Account</li> */}
			<li
				onClick={() => {
					AuthService.logout()
					history.push('/signin')
				}}
			>
				Logout
			</li>
		</ul>
	)

	return (
		<Popover
			overlayClassName='gx-popover-horizantal'
			placement='bottomRight'
			content={userMenuOptions}
			trigger='click'
		>
			<UserOutlined />
		</Popover>
	)
}

export default UserInfo
